<template>
  <div></div>
</template>

<script>
  const Announcement = () => import('./Announcement.vue');
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name : 'AnnouncementListener',
    data() {
      return {
        pnAnnInstance         : 'announcementInst',
        pnFilteredAnnInstance : 'filteredAnnouncementInst',
      }
    },
    computed : {
      ...mapGetters({
        unreadAnnouncements : 'announcement/unreadAnnouncements',
        user                : 'user/user',
      }),
    },
    watch : {
      unreadAnnouncements(value) {
        if (value.length > 0 && this.$store.getters['user/user'] &&
          this.$store.getters['user/user'].user_type !== 0) {
          value.forEach(item => {
            this.showAnnouncement(item.announcement_id, item.content);
          });
        }
      },
    },
    methods : {
      ...mapActions({
        'getUnreadAnnouncements' : 'announcement/getUnreadAnnouncements',
      }),

      /**
       * Announcement Listener
       */
      async announcementListener() {
        var pb = this.$pnGetInstance(this.pnAnnInstance);
        var VueThis = this;

        pb.addListener({
          message : await function(m) {
            var channelName = m.channel;
            var channelGroup = m.subscription;
            var pubTT = m.timetoken;
            var publisher = m.message.ann.publisherName;
            var announcementMsg = m.message.ann.msg;
            var announcementId = m.message.ann.announcementId;
            var content = m.message.ann.content;
            var platform = m.message.ann.platform;

            if (platform === 'website' && VueThis.user.user_type !== 0)
              VueThis.showAnnouncement(announcementId, content);
          },
        });
      },

      async filteredAnnouncementListener() {
        var pb = this.$pnGetInstance(this.pnFilteredAnnInstance);
        var VueThis = this;

        pb.addListener({
          message : await function(m) {
            var channelName = m.channel;
            var channelGroup = m.subscription;
            var pubTT = m.timetoken;
            var publisher = m.message.ann.publisherNamel;
            var announcementMsg = m.message.ann.msg;
            var announcementId = m.message.ann.announcementId;
            var content = m.message.ann.content;
            var platform = m.message.ann.platform;

            if (platform === 'website' && VueThis.user.user_type !== 0)
              VueThis.showAnnouncement(announcementId, content);
          },
        });
      },

      /**
       * Show Announcement
       * @param announcementId
       * @param content
       */
      showAnnouncement(announcementId, content) {
        this.$modal.show(Announcement, {
          id      : announcementId,
          content : content,
        }, {
          height       : 'auto',
          adaptive     : true,
          clickToClose : false,
        });
      },
    },
    created() {
      this.announcementListener();
      this.filteredAnnouncementListener();
    },
    mounted() {
      if (this.$store.getters['user/user'].user_type !== 0) {
        this.$pnSubscribe({
          channels : ['announcement'],
        }, this.pnAnnInstance);
        this.$pnSubscribe({
          channels : [this.$store.getters['user/user'].chat_id],
        }, this.pnFilteredAnnInstance);

        if (localStorage.getItem('user'))
          this.getUnreadAnnouncements();
      }
    },
  }
</script>

